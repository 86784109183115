<template>
  <v-form @submit.prevent="save" ref="form" v-model="valid" lazy-validation>
    <v-text-field
      label="이름"
      v-model.trim="form.mb_name"
      :rules="rules.name()"
    />

   <v-text-field
    label="이메일"
    v-model.trim="form.mb_email"
    :rules="rules.email()"
   />

    <v-btn block color="primary" type="submit" :isLoading="isLoading">
      아이디찾기
    </v-btn>
  </v-form>
</template>

<script>
import validateRules from "../../../util/validateRules";
export default{
  name: "FindIdForm",
  props : {
    isLoading : {
      type : Boolean,
      default : false,
    }
  },
  data(){
    return{
      valid :true,
      form : {
        mb_name : "테스트",
        mb_email : "test@test.com",
      }
    }
  },
  computed:{
    rules: () => validateRules,
  },
  methods : {
    async save() {
      this.$refs.form.validate();
      await this.$nextTick();
      if (!this.valid) return;
      this.$emit("save", this.form);
    },
  }
}
</script>

<style>
</style>